import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Link from "next/link";

function FeaturedBlog(props) {
  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
  };
  return (
    <>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={3}
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        breakpoints={breakpoints}
      >
        <SwiperSlide>
          <Link href="/">
            <a className="d-flex flex-column gap-2 blgthmb">
              <div className="blgimg">
                <img
                  className="d-block w-100 rounded-lg"
                  src={`/images/bolg-1.webp`}
                  alt=""
                />
              </div>
              <h5>Lorem Ipsum is simply dummy text of the printing</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>Joanna Wellick</div>
                <div className="d-flex align-items-center gap-1">
                  <small>June 28, 2023</small>
                  <svg
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.275394 2.90974C0.275394 4.01718 1.16796 4.90974 2.27539 4.90974C3.38283 4.90974 4.27539 4.01718 4.27539 2.90974C4.27539 1.80231 3.38283 0.909744 2.27539 0.909744C1.16796 0.909744 0.275394 1.80231 0.275394 2.90974Z"
                      fill="#6C757D"
                      fill-opacity="0.4"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.32085 10.9097C4.45052 10.9097 5.3663 10.0142 5.3663 8.90967C5.3663 7.8051 4.45052 6.90967 3.32085 6.90967C2.19117 6.90967 1.27539 7.8051 1.27539 8.90967C1.27539 10.0142 2.19117 10.9097 3.32085 10.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 4.90967C15.3597 4.90967 16.2755 4.01424 16.2755 2.90967C16.2755 1.8051 15.3597 0.909668 14.23 0.909668C13.1004 0.909668 12.1846 1.8051 12.1846 2.90967C12.1846 4.01424 13.1004 4.90967 14.23 4.90967Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 16.9097C15.3597 16.9097 16.2755 16.0142 16.2755 14.9097C16.2755 13.8051 15.3597 12.9097 14.23 12.9097C13.1004 12.9097 12.1846 13.8051 12.1846 14.9097C12.1846 16.0142 13.1004 16.9097 14.23 16.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.10303 9.89014L12.4471 13.9293M12.4471 3.89014L5.10303 7.9293"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <small className="ml-1">1K</small>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link href="/">
            <a className="d-flex flex-column gap-2 blgthmb">
              <div className="blgimg">
                <img
                  className="d-block w-100 rounded-lg"
                  src={`/images/blog-2.webp`}
                  alt=""
                />
              </div>
              <h5>Lorem Ipsum is simply dummy text of the printing</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>Joanna Wellick</div>
                <div className="d-flex align-items-center gap-1">
                  <small>June 28, 2023</small>
                  <svg
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.275394 2.90974C0.275394 4.01718 1.16796 4.90974 2.27539 4.90974C3.38283 4.90974 4.27539 4.01718 4.27539 2.90974C4.27539 1.80231 3.38283 0.909744 2.27539 0.909744C1.16796 0.909744 0.275394 1.80231 0.275394 2.90974Z"
                      fill="#6C757D"
                      fill-opacity="0.4"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.32085 10.9097C4.45052 10.9097 5.3663 10.0142 5.3663 8.90967C5.3663 7.8051 4.45052 6.90967 3.32085 6.90967C2.19117 6.90967 1.27539 7.8051 1.27539 8.90967C1.27539 10.0142 2.19117 10.9097 3.32085 10.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 4.90967C15.3597 4.90967 16.2755 4.01424 16.2755 2.90967C16.2755 1.8051 15.3597 0.909668 14.23 0.909668C13.1004 0.909668 12.1846 1.8051 12.1846 2.90967C12.1846 4.01424 13.1004 4.90967 14.23 4.90967Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 16.9097C15.3597 16.9097 16.2755 16.0142 16.2755 14.9097C16.2755 13.8051 15.3597 12.9097 14.23 12.9097C13.1004 12.9097 12.1846 13.8051 12.1846 14.9097C12.1846 16.0142 13.1004 16.9097 14.23 16.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.10303 9.89014L12.4471 13.9293M12.4471 3.89014L5.10303 7.9293"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <small className="ml-1">1K</small>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link href="/">
            <a className="d-flex flex-column gap-2 blgthmb">
              <div className="blgimg">
                <img
                  className="d-block w-100 rounded-lg"
                  src={`/images/blog-3.webp`}
                  alt=""
                />
              </div>
              <h5>Lorem Ipsum is simply dummy text of the printing</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>Joanna Wellick</div>
                <div className="d-flex align-items-center gap-1">
                  <small>June 28, 2023</small>
                  <svg
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.275394 2.90974C0.275394 4.01718 1.16796 4.90974 2.27539 4.90974C3.38283 4.90974 4.27539 4.01718 4.27539 2.90974C4.27539 1.80231 3.38283 0.909744 2.27539 0.909744C1.16796 0.909744 0.275394 1.80231 0.275394 2.90974Z"
                      fill="#6C757D"
                      fill-opacity="0.4"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.32085 10.9097C4.45052 10.9097 5.3663 10.0142 5.3663 8.90967C5.3663 7.8051 4.45052 6.90967 3.32085 6.90967C2.19117 6.90967 1.27539 7.8051 1.27539 8.90967C1.27539 10.0142 2.19117 10.9097 3.32085 10.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 4.90967C15.3597 4.90967 16.2755 4.01424 16.2755 2.90967C16.2755 1.8051 15.3597 0.909668 14.23 0.909668C13.1004 0.909668 12.1846 1.8051 12.1846 2.90967C12.1846 4.01424 13.1004 4.90967 14.23 4.90967Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 16.9097C15.3597 16.9097 16.2755 16.0142 16.2755 14.9097C16.2755 13.8051 15.3597 12.9097 14.23 12.9097C13.1004 12.9097 12.1846 13.8051 12.1846 14.9097C12.1846 16.0142 13.1004 16.9097 14.23 16.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.10303 9.89014L12.4471 13.9293M12.4471 3.89014L5.10303 7.9293"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <small className="ml-1">1K</small>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link href="/">
            <a className="d-flex flex-column gap-2 blgthmb">
              <div className="blgimg">
                <img
                  className="d-block w-100 rounded-lg"
                  src={`/images/blog-2.webp`}
                  alt=""
                />
              </div>
              <h5>Lorem Ipsum is simply dummy text of the printing</h5>
              <div className="d-flex align-items-center justify-content-between">
                <div>Joanna Wellick</div>
                <div className="d-flex align-items-center gap-1">
                  <small>June 28, 2023</small>
                  <svg
                    width="5"
                    height="5"
                    viewBox="0 0 5 5"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.275394 2.90974C0.275394 4.01718 1.16796 4.90974 2.27539 4.90974C3.38283 4.90974 4.27539 4.01718 4.27539 2.90974C4.27539 1.80231 3.38283 0.909744 2.27539 0.909744C1.16796 0.909744 0.275394 1.80231 0.275394 2.90974Z"
                      fill="#6C757D"
                      fill-opacity="0.4"
                    />
                  </svg>
                  <div className="d-flex align-items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.32085 10.9097C4.45052 10.9097 5.3663 10.0142 5.3663 8.90967C5.3663 7.8051 4.45052 6.90967 3.32085 6.90967C2.19117 6.90967 1.27539 7.8051 1.27539 8.90967C1.27539 10.0142 2.19117 10.9097 3.32085 10.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 4.90967C15.3597 4.90967 16.2755 4.01424 16.2755 2.90967C16.2755 1.8051 15.3597 0.909668 14.23 0.909668C13.1004 0.909668 12.1846 1.8051 12.1846 2.90967C12.1846 4.01424 13.1004 4.90967 14.23 4.90967Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.23 16.9097C15.3597 16.9097 16.2755 16.0142 16.2755 14.9097C16.2755 13.8051 15.3597 12.9097 14.23 12.9097C13.1004 12.9097 12.1846 13.8051 12.1846 14.9097C12.1846 16.0142 13.1004 16.9097 14.23 16.9097Z"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.10303 9.89014L12.4471 13.9293M12.4471 3.89014L5.10303 7.9293"
                        stroke="#6C757D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <small className="ml-1">1K</small>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default FeaturedBlog;
