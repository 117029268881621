import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function SellBrandSlider(props) {
  const breakpoints = {
    320: {
      slidesPerView: 3,
    },
    480: {
      slidesPerView: 4,
    },
    640: {
      slidesPerView: 5,
    },
    992: {
      slidesPerView: 6,
    },
    1080: {
      slidesPerView: 7,
    },
    1200: {
      slidesPerView: 8,
    },
  };
  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={20}
        slidesPerView={8}
        navigation
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        breakpoints={breakpoints}
      >
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-apple.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Apple</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-samsung.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Samsung</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-google.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Google Pixel</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-xiaomiRedmi.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Xiaomi/Redmi</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-realme.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Realme</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-onePlus.webp`}
                alt=""
              />
            </div>
            <small className="text-white">One Plus</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-motorola.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Motorola</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-hp.webp`}
                alt=""
              />
            </div>
            <small className="text-white">HP</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-nothing.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Nothing Phone</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-oppo.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Oppo</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-vivo.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Vivo</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-nokia.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Nokia</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-lenovo.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Lenovo</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-asus.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Asus</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-sony.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Sony</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-huawei.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Huawei</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-lg.webp`}
                alt=""
              />
            </div>
            <small className="text-white">LG</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-dell.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Dell</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-microsoft.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Microsoft</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-intel.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Intel</small>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="d-flex flex-column gap-2 text-center">
            <div className="SellBrandSliderlogo">
              <img
                className="d-block mx-auto rounded-circle"
                width={"128px"}
                src={`/images/brand-amazon.webp`}
                alt=""
              />
            </div>
            <small className="text-white">Amazon</small>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SellBrandSlider;
