import React, { Component } from "react";
import Link from "next/link";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import styles from "../styles/Home.module.css";
import Image from "next/image";

function HomepageCategory(props) {
  const { categories, error } = props;
  const s3_upload_image =
    "https://budlisell.s3.ap-south-1.amazonaws.com/uploads";
  return (
    <Container fluid>
      <Row className="justify-content-md-center">
        {!error ? (
          categories.map((category) =>
            category.display_in_homepage ? (
              <Col lg={3} md={6} sm={6} key={category.id} className="mt-2">
                <Card className="text-center inListing">
                  <Card.Body>
                    <Link href={`/${category.slug}`}>
                      <a>
                        <div className={styles.product_img}>
                          <Image
                            src={`${s3_upload_image}${category.image}`}
                            alt={category.name}
                            width="250"
                            height="221"
                            placeholder="blur"
                            blurDataURL="/images/pbi.png"
                          />
                        </div>
                      </a>
                    </Link>
                    <div className="text-center">
                      <Button variant="primary" href={`/${category.slug}`}>
                        {category.name}
                      </Button>{" "}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              ""
            )
          )
        ) : (
          <Col>{error.message}</Col>
        )}
      </Row>
    </Container>
  );
}

export default HomepageCategory;
