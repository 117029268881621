import React, { Component } from 'react'
import Link from "next/link";
import { Container, Card } from 'react-bootstrap';
import { IKImage, IKContext } from "imagekitio-react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import styles from '../styles/Home.module.css';

class RecentlyPurchased extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      products: [],
    };
  }
  UNSAFE_componentWillMount() {
    const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}products/recentPurchased/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}`;
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          products: data,
        })
      )
      .catch((error) => this.setState({ error }));
  }
  render() {
    const { products, error } = this.state;
    return (
      <Container fluid>
        <h2>Recently sold gadgets by our users</h2>
        {!error ? (
          <Carousel
            slidesPerPage={6}
            infinite
            autoPlay={3000}
            stopAutoPlayOnHover={true}
            breakpoints={{
              640: {
                slidesPerPage: 1,
                arrows: false,
              },
              900: {
                slidesPerPage: 2,
                arrows: false,
              },
              1024: {
                slidesPerPage: 3,
                arrows: false,
              },
            }}
          >
            {products.map((product) => (
              <Card
                className="text-center inListing model-list"
                key={product.id}
              >
                <Card.Body>
                  <Link
                    href={`/${product.category_slug}/${product.brand_slug}/${product.slug}`}
                  >

                    <div className={styles.product}>
                      <div className={styles.product_img}>
                        <IKContext urlEndpoint="https://ik.imagekit.io/budli">
                          <IKImage
                            src={`https://ik.imagekit.io/budli/${product.images[0].filename}?tr=h-150,w-150,cm-pad_resize`}
                            alt={product.name}
                            loading="lazy"
                            lqip={{ active: true }}
                          />
                        </IKContext>
                      </div>
                    </div>

                  </Link>
                </Card.Body>
                <Card.Title className={styles.image_caption_text}>
                  <Link
                    href={`/${product.category_slug}/${product.brand_slug}/${product.slug}`}
                    legacyBehavior>
                    {product.name}
                  </Link>
                </Card.Title>
              </Card>
            ))}
          </Carousel>
        ) : (
          <p>{error.message}</p>
        )}
      </Container>
    );
  }
}

export default RecentlyPurchased;