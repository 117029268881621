import Link from "next/link";
import Head from "next/head";
import Image from "next/image";
import LazyLoad from "react-lazyload";
import { Container, Row, Col, Card, ResponsiveEmbed } from "react-bootstrap";
import TestimonialSlider from "../components/TestimonialSlider";
import StepsInBrief from "../components/StepsInBrief";
import RecentlyPurchased from "../components/RecentlyPurchased";
import HomepageCategory from "../components/HomepageCategory";
import InnerLayout from "../components/InnerPageLayout";
import styles from "../styles/Home.module.css";
import Videos from "../components/Videos";
import SellBrandSlider from "../components/SellBrandSlider";
import WhyBudli from "../components/WhyBudli";
import SEOfaq from "../components/SEOfaq";
import FeaturedBlog from "../components/FeaturedBlog";
import LandingFAQ from "../components/LandingFAQ";
import Footer from "../components/Footer";
import SellHeader from "../components/SellHeader";
import FeaturedIn from "../components/FeaturedIn";
import Awards from "../components/Awards";

const s3_front_end_image =
  "https://budlisell.s3.ap-south-1.amazonaws.com/assets/themes/forntend/default/img/";

import { fetchPosts } from "../lib/api";
import fetch from "node-fetch";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import LandingBrandSlider from "../components/LandingBrandSlider";
import Login from "../components/Login";

const getShareCount = (postId) => {
  // Replace this with actual logic to fetch share count
  // For now, returning a placeholder value
  return 123;
};

function Sell({ testimonials, deviceType, categories, posts, error }) {
  if (error) {
    return <p>Error loading posts: {error}</p>;
  }
  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
  };
  return (
    <>
      <Head>
        <title>
          Sell Used Mobiles, Laptops & Other Gadgets Online | Budli.in
        </title>
        {/* Please keep meta description upto 160 words only */}
        <meta
          name="description"
          content="Sell used or old mobile phones, laptops, tablets and other gadgets online on budli.in at the best prices
"
        />
        <meta
          name="Keywords"
          content="sell second hand mobile phone, sell mobile, sell old mobile, used laptop, 
              Online ReCommerce portal, sell mobile online, online ReCommerce portal India, 
              sell used mobile, sell used mobile phone, sell mobile phones, sell used tablet, 
              sell ipod, sell tab, sell used iphone, sell used ipad, sell used laptop, sell macbook, 
              sell old laptop"
        />
        <link rel="canonical" href="/sell" />
      </Head>
      <SellHeader />
      <section className="text-center border-bottom infosec fw-600 text-dark">
        <div className="container">
          <div className="row mob-row10">
            <div className="col-3 py-md-3 py-2 border-right">
              <img src={`/images/info1.svg`} alt="Best Value" width={55} />
              <div className="d-block mt-1">Best Value</div>
            </div>
            <div className="col-3 py-md-3 py-2 border-right">
              <img
                src={`/images/info2.svg`}
                alt="Safe & Secure"
                width={55}
              />
              <div className="d-block mt-1">Safe & Secure</div>
            </div>
            <div className="col-3 py-md-3 py-2 border-right">
              <img src={`/images/info3.svg`} alt="Hassle Free" width={55} />
              <div className="d-block mt-1">Hassle Free</div>
            </div>
            <div className="col-3 py-md-3 py-2">
              <img
                src={`/images/info4.svg`}
                alt="Eco Friendly"
                width={55}
              />
              <div className="d-block mt-1">Eco Friendly</div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 testisec">
        <div className="container">
          <div className="position-relative">
            <div className="testititsec text-center text-lg-left w-100">
              <h2 className="mb-md-3 mb-2 h4 fw-600">
                <Link href="/user-reviews">
                  <a className="text-dark">What Our Users Say</a>
                </Link>
              </h2>
              <div className="text-dark mb-md-3 mb-2 testisubtit">
                Join our{" "}
                <strong className="text-default">
                  600,000+ satisfied customers
                </strong>
                . Discover why they trust Budli for their tech needs
              </div>
            </div>
          </div>
          <TestimonialSlider slideperpage="2" testimonials={testimonials} />
        </div>
      </section>
      <section className="pt-4 pb-md-2 pb-4 whybudly bg-light">
        <div className="container">
          <div className="text-center mb-3">
            <h2 className="mb-1 h4 fw-600">
              The fastest way to sell your used gadget online
            </h2>
            <div>India's first instant Online ReCommerce Portal</div>
          </div>
          <div className="row mob-row5">
            <div className="col-md-4 mb-4 col-6">
              <div className="rounded-lg shadow-sm bg-default-red h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-3">
                <img
                  className="d-block mx-auto rounded-circle"
                  width={100}
                  src={`/images/fastest1.svg`}
                  alt=""
                />
                <div className="text-white">
                  Accept Price Quote and pack your product. It will be picked up
                  from most locations in India and shipped to us for inspection
                  free of cost.
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 col-6">
              <div className="rounded-lg shadow-sm bg-default-red h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-3">
                <img
                  className="d-block mx-auto rounded-circle"
                  width={100}
                  src={`/images/fastest2.svg`}
                  alt=""
                />
                <div className="text-white">
                  Once the product is checked at our end, payment will be sent
                  to you by UPI/online bank transfer/wallet within 4 working
                  days of receipt of your device.
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 col-12">
              <div className="rounded-lg shadow-sm bg-default-red h-100 p-lg-5 p-md-3 p-3 text-center d-flex flex-column gap-3">
                <img
                  className="d-block mx-auto rounded-circle"
                  width={100}
                  src={`/images/fastest3.svg`}
                  alt=""
                />
                <div className="text-white">
                  Once the product is checked at our end, payment will be sent
                  to you by online bank transfer or wallet within 4 working days
                  of receipt of your device.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-4 videosec">
        <div className="container">
          <div className="position-relative">
            <div className="videosecinr text-center text-lg-left w-100">
              <h2 className="mb-2 h4 fw-600">Media & Customer Insights</h2>
            </div>
          </div>
          <Videos />
        </div>
      </section>
      <section className="pb-4">
        <div className="container">
          <div className="col-md-7 mx-auto text-center">
            <h2 className="mb-3 h4 fw-600">
              <Link href="/budli-charity">
                <a className="text-dark">
                  With every transaction you are helping in creating social
                  change. Find out how....
                </a>
              </Link>
            </h2>
          </div>
          <div className="col-md-8 col-lg-6 mx-auto">
            <Link href="/devicetodream">
              <a>
                <Image
                  src={`${s3_front_end_image}device-to-dream-web.jpg`}
                  alt="Budli Device To Dream Programme"
                  width="800"
                  height="300"
                  className="w-100 h-auto"
                />
              </a>
            </Link>
          </div>
        </div>
      </section>
      {/* <section className="py-4 bg-default-blue videosec sellbrand">
            <div className="container">
              <div className="position-relative">
                <div className="videosecinr">
                  <h2 className="mb-2 h4 fw-600 text-white">Top Selling Brands</h2>
                </div>
              </div>
              <SellBrandSlider/>
            </div>
        </section> */}
      <section className="py-4 bg-default-red videosec">
        <div className="container">
          <div className="position-relative">
            <div className="videosecinr">
              <h2 className="mb-2 h4 fw-600 text-white">Brands We Deal In</h2>
            </div>
          </div>
          <LandingBrandSlider />
        </div>
      </section>
      <section className="pt-4 pb-md-2 pb-4 whybudly">
        <div className="container">
          <div className="text-center mb-3">
            <h2 className="mb-1 h4 fw-600">
              <Link href="">
                <a className="text-dark">Why Budli</a>
              </Link>
            </h2>
            <div>Get the best deal while buying or selling</div>
          </div>
          <WhyBudli />
        </div>
      </section>
      {/* <LazyLoad offset={100} once>
          <RecentlyPurchased />
        </LazyLoad> */}

      <section className="pb-lg-5 pb-4">
        <div className="container">
          <h2 className="mb-3 h4 fw-600 text-center">
            In The Spotlight: See Where We've Been Featured
          </h2>
          <LazyLoad offset={100} once>
            <FeaturedIn data={{ s3_url: s3_front_end_image }} />
          </LazyLoad>
        </div>
      </section>
      <section className="pb-lg-5 pb-4">
        <div className="container">
          <h2 className="mb-3 h4 fw-600 text-center col-lg-5 p-0 mx-auto">
            Recognition: Awards we've received for our service and commitment
          </h2>
          <LazyLoad offset={100} once>
            <Awards data={{ s3_url: s3_front_end_image }} />
          </LazyLoad>
        </div>
      </section>

      <section className="py-4 bg-light">
        <div className="container">
          <h2 className="mb-3 h4 fw-600">
            <Link href="/faq">
              <a className="text-dark">Frequently Asked Questions</a>
            </Link>
          </h2>
          <LandingFAQ />
        </div>
      </section>
      <section className="py-4 featuredblogsec">
        <div className="container">
          <div className="text-center mb-md-3 mb-2 p-0 col-lg-8 mx-auto">
            <h2 className="mb-1 h4 fw-600">
              <Link href="">
                <a className="text-dark">Latest from Budli</a>
              </Link>
            </h2>
            <div className="text-dark">
              Explore our blogs and articles for tips, trends, and insights on
              selling used gadgets, e-waste management, and more.
            </div>
          </div>
          {/* <FeaturedBlog/> */}
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: true }}
            breakpoints={breakpoints}
          >
            {posts.map((post) => (
              <SwiperSlide key={post.id}>
                <Link href={post.link}>
                  <a className="d-flex flex-column gap-2 blgthmb">
                    <div className="blgimg">
                      {post._embedded?.["wp:featuredmedia"]?.[0]
                        ?.source_url && (
                          <Image
                          src={
                            post._embedded["wp:featuredmedia"][0].source_url
                          }
                          width={417}
                          height={220}
                          alt={
                            post._embedded["wp:featuredmedia"][0]
                              .alt_text || post.title.rendered
                          }
                          className="d-block w-100 rounded-lg h-auto"
                        /> 
                      )}
                    </div>
                    <h5>{post.title.rendered}</h5>
                    {/* <div className='d-flex align-items-center justify-content-between'>
                        <div className="authername">{post._embedded?.author?.[0]?.name || 'Unknown author'}</div>
                        <div className='d-flex align-items-center gap-1'>
                            <small className="postdate">{new Date(post.date).toLocaleDateString()}</small>
                            <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.275394 2.90974C0.275394 4.01718 1.16796 4.90974 2.27539 4.90974C3.38283 4.90974 4.27539 4.01718 4.27539 2.90974C4.27539 1.80231 3.38283 0.909744 2.27539 0.909744C1.16796 0.909744 0.275394 1.80231 0.275394 2.90974Z" fill="#6C757D" fill-opacity="0.4"/>
                            </svg>
                            <div className='d-flex align-items-center'>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.32085 10.9097C4.45052 10.9097 5.3663 10.0142 5.3663 8.90967C5.3663 7.8051 4.45052 6.90967 3.32085 6.90967C2.19117 6.90967 1.27539 7.8051 1.27539 8.90967C1.27539 10.0142 2.19117 10.9097 3.32085 10.9097Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.23 4.90967C15.3597 4.90967 16.2755 4.01424 16.2755 2.90967C16.2755 1.8051 15.3597 0.909668 14.23 0.909668C13.1004 0.909668 12.1846 1.8051 12.1846 2.90967C12.1846 4.01424 13.1004 4.90967 14.23 4.90967Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.23 16.9097C15.3597 16.9097 16.2755 16.0142 16.2755 14.9097C16.2755 13.8051 15.3597 12.9097 14.23 12.9097C13.1004 12.9097 12.1846 13.8051 12.1846 14.9097C12.1846 16.0142 13.1004 16.9097 14.23 16.9097Z" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5.10303 9.89014L12.4471 13.9293M12.4471 3.89014L5.10303 7.9293" stroke="#6C757D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <small className='ml-1 postshare'>{getShareCount(post.id)}</small>
                            </div>
                        </div>
                    </div> */}
                  </a>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
      <section className="py-4 border-top">
        <div className="container">
          <SEOfaq />
        </div>
      </section>
      <Footer data={{ s3_url: s3_front_end_image }} />
    </>
  );
}

// This function gets called at build time
export async function getServerSideProps(context) {
  try {
    // Fetching WordPress posts
    const posts = await fetchPosts();

    // Fetching testimonials
    const apiUrl = `${process.env.NEXT_PUBLIC_API_URL}testimonials/allTestimonials/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}/type/text/limit/10`;
    const res = await fetch(apiUrl);
    const testimonials = await res.json();

    // Fetching deal of the day
    const dofdApiUrl = `${process.env.NEXT_PUBLIC_API_URL}banners/dealOfTheDay/X-API-KEY/${process.env.NEXT_PUBLIC_API_KEY}`;
    const dealData = await fetch(dofdApiUrl);
    const dealOfDay = await dealData.json();

    // Detecting device type
    const UA = context.req ? context.req.headers["user-agent"] : "";
    const isMobile = UA
      ? Boolean(
          UA.match(/Android|BlackBerry|iPhone|Opera Mini|IEMobile|WPDesktop/i)
        )
      : false;

    // Passing data to the page via props
    return {
      props: {
        testimonials,
        dealOfDay,
        deviceType: isMobile ? "mobile" : "desktop",
        posts,
      },
    };
  } catch (error) {
    return {
      props: {
        error: error.message,
      },
    };
  }
}

export default Sell;
