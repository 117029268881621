import Link from "next/link";
import Image from "next/image";
import { Container, Row, Col } from 'react-bootstrap';
import styles from "../styles/Home.module.css";

function StepsInBrief (props) {
  return (
    <div className={styles.step}>
      <div className={styles.step_top}></div>
      <Container fluid>
        <Row>
          <Col>
            <h2>The fastest way to sell your used gadget online</h2>
            <p>India's first instant Online ReCommerce Portal</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className={`${styles.all_step} animate bounceIn cssanim`}>
              <Image
                src={`${props.data.s3_url}price.png`}
                alt="price"
                width="327"
                height="164"
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
              <p>
                Identify your item and model among the categories, select
                specifications, condition and accessories included to get a
                precise and instant price quote
                <Link href="/how-it-works">...</Link>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={`${styles.all_step} animate bounceIn cssanim`}>
              <Image
                src={`${props.data.s3_url}shiping.png`}
                alt="shiping"
                width="327"
                height="164"
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
              <p>
                Accept Price Quote, print shipping label and pack your
                product. It will be picked up from most locations in India and
                shipped to us for inspection
                <Link href="/how-it-works">...</Link>
              </p>
            </div>
          </Col>
          <Col md={4}>
            <div className={`${styles.all_step} animate bounceIn cssanim`}>
              <Image
                src={`${props.data.s3_url}getPaid.png`}
                alt="getPaid"
                width="327"
                height="164"
                style={{
                  maxWidth: "100%",
                  height: "auto"
                }} />
              <p>
                Once the product is checked at our end, payment will be sent
                to you by online bank transfer or wallet within 4 working days
                of receipt of your device
                <Link href="/how-it-works">...</Link>
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link href="/how-it-works" className={styles.all_step_link}>
              
                Read more about how we make it happen...
              
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default StepsInBrief;